import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import PropTypes from "prop-types"
import Breadcrumb from "../components/Breadcrumb"
import CourseCard from "../components/Card/CourseCard"
import Consult from "../components/Consult"
import FilterButton from "../components/Filter/FilterButton"
import FilterCategory from "../components/Filter/FilterCategory"
import { useLandingPageData } from "../data/useLandingPageData"
import { useFilterData } from "../data/useFilterData"
import { ViewListIcon } from "@heroicons/react/solid"
import { graphql } from "gatsby"
import PaginationWithApi from "../components/PaginationWithApi"
import useSWR from "swr"
import CourseItem from "../components/FindCoursePage/CourseItem"
import { Code } from "react-content-loader"
import FilterBlock from "../components/Filter/FilterBlock"
import Sidebar from "../components/FindCoursePage/Sidebar"
import Hint from "../components/FindCoursePage/Hint"
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo"
import { useMetadata } from "../data/use-metadata"
import { getParams } from "../functions/getParams"
import { findPosition, handleFilterOnChange } from "../functions/forFilterPage"

const Course = ({ data }) => {
  const { popularCourses, recommendedCourses } = data

  const { consultGatsbyImageData, consultTitle } = useLandingPageData()

  const { allTopic, allLevel, allCategory } = useFilterData()

  const slug = "course"

  // 控制各層篩選標籤按鈕，並搜集按鈕 contentful_id 來發 req
  const [topicId, setTopicId] = useState([])
  const [topicCheckedState, setTopicCheckedState] = useState(
    new Array(allTopic.length).fill(false)
  )
  const [levelId, setLevelId] = useState([])
  const [levelCheckedState, setLevelCheckedState] = useState(
    new Array(allLevel.length).fill(false)
  )
  const [categoryId, setCategoryId] = useState([])
  const [categoryCheckedState, setCategoryCheckedState] = useState(
    new Array(allCategory.length).fill(false)
  )

  // 控制篩選種類按鈕
  const [filterCategoryName, setFilterCategoryName] = useState("最新上架")

  // 控制手機版標籤 展開更多 功能
  const [isTopicOpen, setIsTopicOpen] = useState(false)
  const [isLevelOpen, setIsLevelOpen] = useState(false)
  const [isCategoryOpen, setIsCategoryOpen] = useState(false)

  // 追蹤目前分頁位置
  const [currentPage, setCurrentPage] = useState(1)

  // 每頁顯示課程數量
  const limit = 8

  // 取得網址參數
  const [tag, type] = getParams()

  // 組合出所有標籤的字典，最後利用參數 tag(標籤名)來找到該標籤的 contentful_id
  const { topicDict, levelDict, categoryDict } = useFilterData()

  const tagDict = { ...topicDict, ...levelDict, ...categoryDict }

  const id = tagDict[tag]

  useEffect(() => {
    // 若 url 帶有參數，則在畫面首次 render 時執行篩選函式
    if (type === "topic") {
      const position = findPosition(allTopic, id)
      handleFilterOnChange(
        allTopic,
        position,
        topicCheckedState,
        setTopicCheckedState,
        setTopicId
      )
    }
    if (type === "category") {
      const position = findPosition(allCategory, id)
      handleFilterOnChange(
        allCategory,
        position,
        categoryCheckedState,
        setCategoryCheckedState,
        setCategoryId
      )
    }
    if (type === "level") {
      const position = findPosition(allLevel, id)
      handleFilterOnChange(
        allLevel,
        position,
        levelCheckedState,
        setLevelCheckedState,
        setLevelId
      )
    }
  }, [id, type])

  const fetcher = async url => await fetch(url).then(res => res.json())

  const { data: courseData, error } = useSWR(
    `https://cdn.contentful.com/spaces/${
      process.env.GATSBY_CONTENTFUL_SPACE_ID
    }/environments/master/entries?access_token=${
      process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN
    }&content_type=course${
      topicId.length ? `&fields.topic.sys.id[in]=${topicId.join(",")}` : ""
    }${levelId.length ? `&fields.level.sys.id[in]=${levelId.join(",")}` : ""}${
      categoryId.length
        ? `&fields.category.sys.id[in]=${categoryId.join(",")}`
        : ""
    }${
      filterCategoryName === "熱門點播" ? "&fields.isPopular=true" : ""
    }&limit=${limit}&skip=${(currentPage - 1) * limit}&order=-sys.updatedAt
`,
    fetcher
  )

  // 課程總數
  const totalCourses = courseData && courseData?.total

  // 篩選種類按鈕
  const filterCategoryInfo = ["最新上架", "熱門點播"]

  const filterCategories = filterCategoryInfo.map((name, idx) => (
    <FilterCategory
      name={name}
      key={idx}
      isCurrentCategory={filterCategoryName === name}
      setFilterCategoryName={setFilterCategoryName}
      setCurrentPage={setCurrentPage}
    />
  ))

  // 三層篩選按鈕
  const topicItems = allTopic.map((topic, idx) => {
    const { id, name } = topic
    return (
      <FilterButton
        key={id}
        setCurrentPage={setCurrentPage}
        idx={idx}
        checkedState={topicCheckedState}
        setCheckedState={setTopicCheckedState}
        setId={setTopicId}
        allTags={allTopic}
      >
        {name}
      </FilterButton>
    )
  })
  const levelItems = allLevel.map((level, idx) => {
    const { id, name } = level
    return (
      <FilterButton
        key={id}
        setCurrentPage={setCurrentPage}
        idx={idx}
        checkedState={levelCheckedState}
        setCheckedState={setLevelCheckedState}
        setId={setLevelId}
        allTags={allLevel}
      >
        {name}
      </FilterButton>
    )
  })
  const categoryItems = allCategory.map((category, idx) => {
    const { id, name } = category
    return (
      <FilterButton
        key={id}
        setCurrentPage={setCurrentPage}
        idx={idx}
        checkedState={categoryCheckedState}
        setCheckedState={setCategoryCheckedState}
        setId={setCategoryId}
        allTags={allCategory}
      >
        {name}
      </FilterButton>
    )
  })

  const crumbs = [
    {
      name: "首頁",
      url: "/",
    },
    {
      name: "找課程",
      url: `/${slug}`,
    },
  ]

  const tags = {}

  courseData?.includes?.Entry.forEach(i => {
    tags[i.sys.id] = i.fields.name
  })

  // 預設 loader 跑 12 個 (符合每頁課程數)
  const loadingItems = [...new Array(limit).keys()].map(i => <Code key={i} />)

  // 課程區塊
  const courseCards = (() => {
    // 有 error
    if (error) return <Hint>failed to load</Hint>

    // 沒資料顯示 Loading 效果
    if (!courseData) return loadingItems

    // 有資料的情況
    if (courseData.total === 0) {
      return <Hint>抱歉…沒有符合的結果</Hint>
    }

    return courseData.items.map((item, idx) => {
      const {
        title,
        description,
        topic,
        level,
        category,
        slug,
        hours,
        numberOfPeople,
      } = item.fields

      const topicArray = topic.map(i => ({
        name: tags[i.sys.id],
        id: i.sys.id,
      }))
      const levelArray = level.map(i => ({
        name: tags[i.sys.id],
        id: i.sys.id,
      }))
      const categoryArray = category.map(i => ({
        name: tags[i.sys.id],
        id: i.sys.id,
      }))

      return (
        <CourseCard
          key={idx}
          title={title}
          description={description}
          topic={topicArray}
          level={levelArray}
          category={categoryArray}
          slug={slug}
          hours={hours}
          numberOfPeople={numberOfPeople}
          hasButton={false}
          isCreateNewPage={true}
        />
      )
    })
  })()

  // 精選區塊
  const recommendedCoursesItems = recommendedCourses.nodes.map(course => (
    <CourseItem key={course.id} course={course} />
  ))

  // 熱門區塊
  const popularCoursesItems = popularCourses.nodes.map(course => (
    <CourseItem key={course.id} course={course} />
  ))

  const { siteUrl } = useMetadata()
  const canonical = `${siteUrl}/${slug}`

  return (
    <Layout>
      <GatsbySeo
        title="找課程"
        canonical={canonical}
        openGraph={{
          url: canonical,
          title: "找課程",
        }}
      />

      <div className="wrapper mt-12 mb-24 sm:mb-36">
        <Breadcrumb crumbs={crumbs} />
        <h1 className="text-3xl leading-tight tracking-wide font-bold text-neutral-800 mt-12 mb-8 sm:text-5xl lg:mb-8">
          找課程
        </h1>
        <div className="sm:flex">
          <FilterBlock
            name="課程主題"
            checkedState={topicCheckedState}
            setCheckedState={setTopicCheckedState}
            setCurrentPage={setCurrentPage}
            setId={setTopicId}
            length={allTopic.length}
            items={topicItems}
            isOpen={isTopicOpen}
            setIsOpen={setIsTopicOpen}
          />
        </div>
        <div className="w-full mt-2.5 border border-neutral-300 sm:mt-0" />
        <div className="sm:flex sm:mt-5">
          <FilterBlock
            name="適合對象"
            checkedState={levelCheckedState}
            setCheckedState={setLevelCheckedState}
            setCurrentPage={setCurrentPage}
            setId={setLevelId}
            length={allLevel.length}
            items={levelItems}
            isOpen={isLevelOpen}
            setIsOpen={setIsLevelOpen}
          />
        </div>
        <div className="w-full mt-2.5 border border-neutral-300 sm:mt-0" />
        <div className="sm:flex sm:mt-5">
          <FilterBlock
            name="課程類型"
            checkedState={categoryCheckedState}
            setCheckedState={setCategoryCheckedState}
            setCurrentPage={setCurrentPage}
            setId={setCategoryId}
            length={allCategory.length}
            items={categoryItems}
            isOpen={isCategoryOpen}
            setIsOpen={setIsCategoryOpen}
          />
        </div>
        <div className="w-full mt-2.5 border border-neutral-300 sm:mt-0" />
        <div className="flex mt-16 mb-4" id="anchor_link">
          <ViewListIcon className="w-5 mr-4 text-neutral-400" />
          {filterCategories}
        </div>
        <div className="lg:grid lg:grid-cols-5 lg:gap-8">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:col-span-4 lg:h-fit">
            {courseCards}
            <div className="sm:col-span-2">
              <PaginationWithApi
                slug={slug}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                total={totalCourses}
                limit={limit}
              />
            </div>
          </div>
          <Sidebar
            popularItems={popularCoursesItems}
            recommendedItems={recommendedCoursesItems}
          />
        </div>
      </div>
      <Consult
        gatsbyImageData={consultGatsbyImageData}
        consultTitle={consultTitle}
      />
    </Layout>
  )
}

Course.propTypes = {
  data: PropTypes.object,
}

export default Course

export const pageQuery = graphql`
  {
    recommendedCourses: allContentfulCourse(
      limit: 3
      sort: { fields: updatedAt, order: DESC }
      filter: { isRecommended: { eq: true } }
    ) {
      nodes {
        id
        title
        hours
        numberOfPeople
        slug
      }
    }
    popularCourses: allContentfulCourse(
      limit: 3
      sort: { fields: updatedAt, order: DESC }
      filter: { isPopular: { eq: true } }
    ) {
      nodes {
        id
        title
        hours
        numberOfPeople
        slug
      }
    }
  }
`
