import React from "react"
import PropTypes from "prop-types"
import Link from "../Link"
import { ClockIcon, UserIcon } from "@heroicons/react/solid"

const CourseItem = ({ course, isOnlineCourse = false }) => {
  const { title, hours, numberOfPeople, slug, minute } = course

  const courseSlug = isOnlineCourse
    ? `/online-course/${slug}`
    : `/course/${slug}`

  return (
    <li className="mb-4 group">
      <Link to={courseSlug}>
        <p className="text-xl leading-loose font-bold text-neutral-600 mt-4 mb-2 ease-in-out duration-300 group-hover:text-amber-400 sm:text-2xl">
          {title}
        </p>
        <div className="flex">
          <div className="flex justify-center items-center pr-4">
            <ClockIcon className="text-neutral-300 w-5" />
            <p className="!leading-normal text-neutral-400 pl-2 sm:text-lg">
              {isOnlineCourse
                ? `${(minute / 60).toFixed(1)}小時`
                : `${hours}小時`}
            </p>
          </div>
          {numberOfPeople && (
            <div className="flex justify-center items-center">
              <UserIcon className="text-neutral-300 w-5" />
              <p className="!leading-normal text-neutral-400 pl-2 sm:text-lg">
                {numberOfPeople}人
              </p>
            </div>
          )}
        </div>
      </Link>
    </li>
  )
}

CourseItem.propTypes = {
  course: PropTypes.object,
  hasNumberOfPeople: PropTypes.bool,
  isOnlineCourse: PropTypes.bool,
}

export default CourseItem
